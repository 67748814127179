
import { Component } from 'vue-property-decorator';
import BaseView from '@/views/BaseView.vue';

@Component
export default class ConvertTransfer extends BaseView {
  private input = '';
  private output = '';

  private convert() {
    this.output = this.input
      .slice(this.input.indexOf('■'), this.input.indexOf('(運賃内訳)'))
      .replace('---\n', '');
  }

  /**
   * 読み込み
   */
  private async read() {
    this.input = await navigator.clipboard.readText();
  }

  /**
   * コピー
   */
  private async copy() {
    await navigator.clipboard.writeText(this.output);
  }
}


import { Component, Watch } from 'vue-property-decorator';
import Utils from '@/common/Utils';
import MylistAssistantHelper from '@/helpers/MylistAssistantHelper';
import { MusicStyle } from '@/models/MylistAssistantModel';
import BaseView from '@/views/BaseView.vue';
import CreateCustomMylistRequestObject from '@/Objects/NicoMylistAutoGen/CreateCustomMylistRequestObject';

@Component({})
export default class MylistAssistant extends BaseView {
  private musicList: Array<MusicStyle>;
  private music: MusicStyle;

  /** Parameter of Custom Mylist */
  private customMylistParameter: CreateCustomMylistRequestObject;

  private isModalOpen: boolean;

  /** Custom Mylist Dialog Open Flag */
  private isEditDialogOpen: boolean;

  private hasEditAuth: boolean;
  private hasMasterEditAuth: boolean;

  constructor() {
    super();

    this.musicList = [];
    this.music = {
      title: '',
      niconico_id: '',
      favorite: false,
      skip: false,
      memo: ''
    };
    this.customMylistParameter = {
      email: '',
      password: '',
      mylist_title: 'CustomMylist',
      count: 100
    };
    this.isModalOpen = false;
    this.isEditDialogOpen = false;
    this.hasEditAuth = false;
    this.hasMasterEditAuth = false;
  }

  @Watch('music.niconico_id')
  private onChangeNiconicoId() {
    if (this.checkExist(this.music.niconico_id)) {
      this.music = this.musicList.filter(music => music.niconico_id === this.music.niconico_id)[0];
    }
  }

  /**
   * Get State of Dialog Open
   * @returns Is Dialog Open
   */
  private get isDialogOpen(): boolean {
    return this.isModalOpen || this.isEditDialogOpen;
  }

  private async mounted(): Promise<void> {
    if (!await MylistAssistantHelper.getAuthOfView()) {
      await Utils.changePage(this.$router, '/');
    }

    this.hasEditAuth = await MylistAssistantHelper.getAuthOfEdit();
    this.hasMasterEditAuth = await MylistAssistantHelper.getAuthOfMasterEdit();

    this.musicList = await MylistAssistantHelper.getAll();
  }

  private clickAdd(): void {
    if (!this.hasMasterEditAuth) return;

    this.music = {
      title: '',
      niconico_id: '',
      favorite: false,
      skip: false,
      memo: ''
    };
    this.isModalOpen = true;
  }

  private clickEdit(index: number): void {
    if (!this.hasEditAuth) return;

    this.music = this.musicList[index];
    this.isModalOpen = true;
  }

  private async clickDelete(index: number): Promise<void> {
    if (!this.hasMasterEditAuth) return;

    await MylistAssistantHelper.delete(index);
    this.musicList = await MylistAssistantHelper.getAll();
  }

  /**
   * Check Exist Music by NiconicoID
   * @param niconico_id NiconicoID
   * @returns Exist: true, Not Exist: false
   */
  private checkExist(niconico_id: string): boolean {
    return this.musicList.filter(music => music.niconico_id === niconico_id).length !== 0;
  }

  private async clickNowPlaying(): Promise<void> {
    if (!this.hasMasterEditAuth) return;

    const music = await MylistAssistantHelper.getNowPlayingInfo();
    this.music.niconico_id = music.video_id;
    this.music.title = music.title;
  }

  private async clickGetInfo(id: string): Promise<void> {
    if (!this.hasMasterEditAuth) return;

    const music = await MylistAssistantHelper.getNiconicoInfo(id);

    this.music.title = music.title;
  }

  private async clickDialogApply(): Promise<void> {
    this.music.favorite = [1, true].indexOf(this.music.favorite) !== -1;
    this.music.skip = [1, true].indexOf(this.music.skip) !== -1;
    if (this.music.music_id !== undefined) {
      if (!this.hasEditAuth) return;
      await MylistAssistantHelper.update(this.music.music_id, this.music);
    } else {
      if (!this.hasMasterEditAuth) return;
      await MylistAssistantHelper.add(this.music);
    }
    this.isModalOpen = false;
    this.musicList = await MylistAssistantHelper.getAll();
  }

  private clickDialogCancel(): void {
    this.isModalOpen = false;
  }

  /**
   * Click Auto Button
   */
  private clickAuto(): void {
    this.isEditDialogOpen = true;
  }

  /**
   * Click Create Button
   */
  private async clickCreate(): Promise<void> {
    MylistAssistantHelper.createCustomMylist(this.customMylistParameter);
  }

  /**
   * Click Cancel Button of Click Edit Dialog
   */
  private clickEditDialogCancel(): void {
    this.isEditDialogOpen = false;
  }
}


import { Component, Watch } from 'vue-property-decorator';
import dayjs from 'dayjs';
import Web from '@/Web';
import BaseView from '@/views/BaseView.vue';

@Component
export default class CreateBibliography extends BaseView {
  private baseType: 'web' | 'book' | 'thesis' = 'web';

  private convertType: 'text' = 'text';

  private web: WebStyle = {
    page_title: '',
    cite_title: '',
    url: '',
    read: dayjs().format('YYYY-MM-DD'),
  };

  private book: BookStyle = {
    title: '',
    authors: [''],
    created: dayjs('0000-00-00').format('YYYY-MM-DD'),
    read: dayjs().format('YYYY-MM-DD'),
  };

  private thesis: ThesisStyle = {
    title: '',
    authors: [''],
    created: dayjs('0000-00-00').format('YYYY-MM-DD'),
    read: dayjs().format('YYYY-MM-DD'),
  };

  @Watch('baseType')
  private onChangeType() {
    this.initAllStyle();
  }

  private get output(): string {
    switch (this.baseType) {
      case 'web':
        return [
          `『${this.web.page_title}』`,
          this.web.cite_title,
          this.web.url,
          this.web.read,
        ].join(', ');

      case 'book':
        return [
          `『${this.book.title}』`,
          this.book.authors.map((author) => {
            return author;
          }),
          this.book.created,
          this.book.read,
        ].join(', ');

      case 'thesis':
        return [
          `『${this.thesis.title}』`,
          this.thesis.authors.map((author) => {
            return author;
          }),
          this.thesis.created,
          this.thesis.read,
        ].join(', ');

      default:
        return '';
    }
  }

  private created() {
    this.initAllStyle();
  }

  private initAllStyle() {
    this.initWebStyle();
    this.initBookStyle();
    this.initThesisStyle();
  }

  private initWebStyle() {
    this.web.page_title = '';
    this.web.cite_title = '';
    this.web.url = '';
    this.web.read = dayjs().format('YYYY-MM-DD');
  }

  private initBookStyle() {
    this.book.title = '';
    this.book.authors = [''];
    this.book.created = dayjs('0000-00-00').format('YYYY-MM-DD');
    this.book.read = dayjs().format('YYYY-MM-DD');
  }

  private initThesisStyle() {
    this.thesis.title = '';
    this.thesis.authors = [''];
    this.thesis.created = dayjs('0000-00-00').format('YYYY-MM-DD');
    this.thesis.read = dayjs().format('YYYY-MM-DD');
  }

  /**
   * URL変換
   */
  private encodeUrl() {
    this.web.url = decodeURI(this.web.url);
  }

  /**
   * Web情報取得
   */
  private async getWebInfo() {
    const result = await Web.getWebInfo(this.web.url);

    if (typeof result !== 'boolean') {
      this.web.page_title = result;
    }
  }

  /**
   * クリア
   */
  private clear() {
    this.initAllStyle();
  }

  /**
   * コピー
   */
  private copy() {
    navigator.clipboard.writeText(this.output);
  }
}

interface WebStyle {
  page_title: string;
  cite_title: string;
  url: string;
  authors?: Array<string>;
  created?: string;
  read: string;
}

interface BookStyle {
  title: string;
  authors: Array<string>;
  state?: string;
  created: string;
  startPage?: number;
  endPage?: number;
  read: string;
}

interface ThesisStyle {
  title: string;
  authors: Array<string>;
  state?: string;
  created: string;
  startPage?: number;
  endPage?: number;
  read: string;
}

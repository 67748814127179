
import { Component, Vue } from 'vue-property-decorator';
import AuthHelper from '@/Auth/AuthHelper';
import Utils from '@/common/Utils';

@Component({})
export default class AppNavigator extends Vue {
  private csrfToken: string;
  private userName: string;

  constructor() {
    super();

    this.csrfToken = '';
    this.userName = '';
  }

  private get isLogin(): boolean {
    return this.userName !== '';
  }

  private get logoutActionPath(): string {
    return `${Utils.getHostWithProtocol()}/logout`;
  }

  private async mounted(): Promise<void> {
    Utils.overlay();

    this.csrfToken = await AuthHelper.getCsrfToken();
    this.userName = await AuthHelper.getUserName();

    Utils.clearOverlay();
  }

  private async clickHome(): Promise<void> {
    await Utils.changePage(this.$router, '/');
  }

  private async clickLogin(): Promise<void> {
    await Utils.changePage(this.$router, '/login', false);
  }

  private async clickRegister(): Promise<void> {
    await Utils.changePage(this.$router, '/register', false);
  }

  private clickLogout(): void {
    (document.getElementById('logout-form') as HTMLFormElement).submit();
  }
}

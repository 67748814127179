
import { Component } from 'vue-property-decorator';
import BaseView from '@/views/BaseView.vue';

@Component
export default class FilterInPokemonGo extends BaseView {
  private checkColor: boolean;
  private checkLegend: boolean;
  private checkDate: boolean;
  private isColor = false
  private isLegend = false
  private date = 1

  public constructor() {
    super();

    this.checkColor = false;
    this.checkLegend = false;
    this.checkDate = false;
  }

  private get output(): string {
    let options: Array<string> = [];

    if (this.checkColor) {
      options.push(this.isColor ? '色違い' : '!色違い');
    }

    if (this.checkLegend) {
      options.push(this.isLegend ? '伝説のポケモン' : '!伝説のポケモン');
    }

    if (this.checkDate) {
      options.push(`日数-${this.date}`);
    }

    return options.join('&');
  }

  /**
   * コピー
   */
  private async copy() {
    await navigator.clipboard.writeText(this.output);
  }
}

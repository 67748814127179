
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class Adsense extends Vue {
  @Prop({
    default: ''
  })
  private clientId: string;

  @Prop({
    default: ''
  })
  private isDev: boolean;

  @Prop({
    default: ''
  })
  private slotNum: string;
}

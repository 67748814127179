
import { Vue, Component } from 'vue-property-decorator';
import Links from '@/components/Links.vue';

@Component({
  components: {
    Links,
  },
})
export default class Error extends Vue {}


import AppNavigator from '@/Navigator/AppNavigator.vue';
import { Vue, Component } from 'vue-property-decorator';
import Adsense from '@/components/Adsense.vue';
import Utils from '@/common/Utils';
import SettingHelper from '@/helpers/SettingHelper';
import TitleHelper from '@/helpers/TitleHelper';

@Component({
  components: {
    Adsense,
    AppNavigator,
  },
})
export default class App extends Vue {
  private clientId: string;
  private slotNum1: string;
  private slotNum2: string;
  private isDev: boolean;
  private clientWidth: number;

  public constructor() {
    super();

    this.clientId = '';
    this.slotNum1 = '';
    this.slotNum2 = '';
    this.isDev = true;
    this.clientWidth = 0;
  }

  private async created(): Promise<void> {
    this.clientId = await SettingHelper.getSetting('ADSENCE_CLIENT_ID');
    this.slotNum1 = await SettingHelper.getSetting('ADSENCE_SLOT_NUM1');
    this.slotNum2 = await SettingHelper.getSetting('ADSENCE_SLOT_NUM2');
    this.isDev = Utils.getEnv() === 'development';
    this.clientWidth = document.body.clientWidth;
  }

  private mounted(): void {
    window.addEventListener('resize', () => {
      if (Utils.isSmartphone(this.clientWidth) !== Utils.isSmartphone()) {
        window.location.reload();
      }
      this.clientWidth = document.body.clientWidth;
    });

    TitleHelper.setTitleByPath(location.pathname);
  }

  private get isSmartphone(): boolean {
    return Utils.isSmartphone();
  }

  private changeHome(): void {
    this.$router.push({
      path: '/'
    });
  }
}

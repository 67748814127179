
import { Vue, Component } from 'vue-property-decorator';
import Utils from '@/common/Utils';

@Component({})
export default class BaseView extends Vue {
  protected isLeft: boolean;

  public constructor() {
    super();

    this.isLeft = true;
  }

  protected get isSmartphone(): boolean {
    return Utils.isSmartphone();
  }

  protected leftClick(): void {
    this.isLeft = true;
  }

  protected rightClick(): void {
    this.isLeft = false;
  }
}
